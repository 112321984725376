
.selector {
    position: relative;
    display: block;

    /* prevent image dragging (set the onclick on the .selector instead) */
    img {
        pointer-events: none;
        user-select: none;
    }
    
    .check-circle {
        $check-circle-height: 1.25em;
        
        font-size: 1rem;
        padding: .1rem;
        width: $check-circle-height;
        height: $check-circle-height;
        line-height: $check-circle-height;
        background-color: $primary;
        text-align: center;
        color: white;
        border: 1px solid white;
        border-radius: 50%;

        position: absolute;
        bottom: -$check-circle-height * .25;
        right: -$check-circle-height * .25;
    }
}