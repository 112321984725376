.cartSection__title {
    background-color: $light;
    padding: .25rem .5rem;
    font-weight: $headings-font-weight; 
    font-size: $h4-font-size; 
}

.cartInvoice {
    $price-font-size: $h4-font-size;

    &__row {
        border-bottom: 1px solid $border-color;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: 
            "title price"
            "option price";
    }

    &__title {
        grid-area: title;
        font-size: $h4-font-size;
        font-weight: $headings-font-weight;
    }
    &__option {
        grid-area: option;
    }
    &__price {
        grid-area: price;
        align-self: flex-end;
        font-size: $price-font-size;
    }

    .price_fs {
        font-size: $price-font-size;
    }
}