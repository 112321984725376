.switch {
    $switch-toggler-size: 1em;
    $switch-padding: 2px;
    $switch-width: calc((#{$switch-toggler-size} + #{$switch-padding}) * 2);
    $switch-color-disabled: $gray-500;
    $switch-color-active: $primary;
    
    border-radius: 999px;
    width: $switch-width;
    background-color: white;
    border: 1px solid $switch-color-disabled;
    padding: 2px;
    transition: $transition-base;
    display: inline-block;

    input {
        display: none;
    }

    &__toggle {
        display: block;
        width: $switch-toggler-size;
        height: $switch-toggler-size;
        border-radius: 999px;
        background-color: $switch-color-disabled;
        transition: $transition-base;
    }

    &--active {
        border-color: $switch-color-active;
    }

    &--active &__toggle {
        background-color: $switch-color-active;
        margin-left: calc(#{$switch-toggler-size} - #{$switch-padding});
    }
}