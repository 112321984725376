.zipcode {
    &__header {
        background-image: url('../../img/mazda/zipcode-bg.jpg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: auto 200px;
        overflow: hidden;
        color: white;
        flex-shrink: 0;
        text-align: center;
        padding: 1rem;

        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            background-size: auto 150px;
        }
        

        .header {
        
            &__cars {
                display: flex;
                align-items: flex-end;
                justify-content: center;

                img {
                    object-fit: contain;
                    height: 130px;
                    width: auto;
                }
            }
        }
    }
}