.protectionPlan {
    text-align: center;
    position: relative;
    border-radius: 1rem;
    height: 100%;

    &--selected {
        border-color: $primary;
        border-top-right-radius: 0;
    }

    &__check_container {
        position: absolute;
        top: 0;
        right: 0;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 3em;
        height: 3em;

        background-image: linear-gradient(45deg, transparent 50%, $primary 50%);
        color: white;
    }

    &__check {
        grid-column: 2 / span 1;
        width: 100%;
        height: auto;
    }

    &__header {
        display: grid;
        grid-template-columns: 2.5rem 1fr 2.5rem;
        align-items: center;
    }

    &__number {
        font-size: 5rem;
        line-height: 1;
        color: white;
        -webkit-text-fill-color: currentColor; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $card-border-color;
    }
}