.invoice {
    $optionInvoice-padding-y: 1rem;
    $optionInvoice-img-height: 100px;
    $optionInvoice-icon-height: $optionInvoice-padding-y * 2;


    $mw-lg-breakpoint: calc( map-get($grid-breakpoints, "lg") - 1px);
    @media screen and (max-width: $mw-lg-breakpoint) {

        &--options {
            position: static;
        
            margin-left: -$container-padding-x;
            margin-right: -$container-padding-x;
        }
    }

    background-color: $primary;
    border-top: 2px solid $primary;
    color: white;
    position: relative;
    padding: $optionInvoice-padding-y 0;
    position: sticky;
    bottom: 0;
    z-index: $zindex-sticky;

    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {        
        &__pricing &__total {
            display: none;
        } 
    }

    &__img {
        position: absolute;
        left: calc( .5 * var(--bs-gutter-x)); /* bootstrap col's padding */
        bottom: 100%;
        height: 5rem;
    }

    &__chevron {
        padding: 0.5em;
        width: $optionInvoice-icon-height;
        height: $optionInvoice-icon-height;
        line-height: $optionInvoice-icon-height;
        text-align: center;
        background-color: white;
        color: $primary;
        border: 2px solid currentColor;
        border-radius: 50%;
        display: grid;
        place-content: center;

        position: absolute;
        right: 0;
        bottom: 0;
    }   
}

.invoiceRow__chevron {
    transition: $accordion-icon-transition;

    &--flipped {
        transform: rotate(180deg);
    }
}