.formConfirmation {
    min-height: 100vh;
    background-color: $light;
    padding-top: 10vh;

    &-content {
        text-align: center;
    }

    &-card {
        width: fit-content;
        margin: 0 auto;
    }

    &-body {
        padding: 1.5rem;

        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            padding: 3rem 4rem;
        }
    }

    &-check {
        font-size: 5rem;
        line-height: 1;
        color: $success;
       
        @media screen and (min-width: map-get($grid-breakpoints, "md")) {
            font-size: 7rem;
        }
    }
}