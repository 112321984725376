// needs a relative parent
.triangle {
    $triangle-height: 4rem;

    position: absolute;
    top: 0;
    right: 0;

    // https://stackoverflow.com/questions/7073484/how-do-css-triangles-work/24808936#24808936
    height: calc(#{$triangle-height} / 1.41); // this is the diagonal line of the triangle
    width: $triangle-height; 
    transform-origin: right bottom;
    transform: rotate(45deg);
    overflow: hidden;

    &__btn {
        width: 100%;
        height: 100%;
        transform-origin: right bottom;
        transform: rotate(-45deg);
        background-color: $primary;
        color: white;

        position: relative;

        svg {
            position: absolute;
            top: .35rem;
            right: .35rem;
        }
    }
}