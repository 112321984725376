.paymentType {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    &__tab {
        padding: 1rem;
        transition: $transition-base;
        font-weight: bold;

        &.active {
            background-color: $primary;
            color: white;
        }
    }
}