.optionCard {

    label {
        cursor: inherit; // card is used as button (browser sets cursor default)
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'xxl') ) {
        flex-direction: row-reverse
    }

    @media screen and (min-width: map-get($grid-breakpoints, 'xxl') ) {
        &__img {
            width: 20%;
            margin: 1rem;
            align-self: center;
        }
    }
}