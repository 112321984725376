.carousel.carousel--inside {
    display: flex;

    .carousel-control-prev, 
    .carousel-control-next {
        position: static;
        filter: #{"invert()"};
    }

    .carousel-control-prev {
        order: -1;
    }
}