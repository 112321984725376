.steps {
    text-align: center;
    border-bottom: 1px solid $gray-400;

    $mw-lg-breakpoint: calc( map-get($grid-breakpoints, "lg") - 1px);
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
        display: flex;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    &__item {
        width: 100%;
        display: grid;
        grid-template-columns: 2rem auto 2rem;
        margin-bottom: -1px;

        @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
            grid-template-columns: 0px 1fr 1fr;
        }

        @media screen and (max-width: $mw-lg-breakpoint) {
            &:not(.active) {
                display: none;
            }
        }
    }

    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {        
        &__arrow--back {
            display: none;
        }

        &__arrow--next {
            pointer-events: none;
        }
    }

    &__link {
        grid-column: 2 / span 1;
        flex-grow: 1;
        color: $body-color;
        display: inline-block;
        margin: 0 auto;

        padding-bottom: .25rem;

        &.active {
            border-bottom: 2px solid $primary;
        }
    }

    .is-disabled {
        color: $text-muted;
        pointer-events: none;
    }
}