.ratio-4-3 {
    aspect-ratio: 4/3;
    object-fit: contain;
}

.ratio-1-1 {
    aspect-ratio: 1 / 1;
    object-fit: contain;
}

.fit-cover {
    object-fit: cover;
}

.fit-contain {
    object-fit: contain;
}

.reset-btn {
    display: inline-block;
    font-weight: unset;
    line-height: unset;
    color: unset;
    text-align: unset;
    text-decoration: unset;
    vertical-align: unset;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: unset;
    padding: unset;
    font-size: unset;
    border-radius: unset;
}

.color-display {
    padding-top: 100%;
    width: 100%;
    border-radius: $border-radius;
}