html {
  scroll-padding: 5vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__footer {
    margin-top: auto;
  }
}