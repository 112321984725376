// fade-in / fade-out component, but .fade was already used we we used .swap instead
.swap {
    display: grid;
    grid-template-areas: "stack";

    &>* {
        opacity: 0;
        transition: $transition-base;
        grid-area: stack;

        &.swap__in {
            opacity: 1;
        }
        &:not(.swap__in) {
            pointer-events: none;
        }
    }
}